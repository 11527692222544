import { FC } from 'react';
import { Box } from '@mui/material/';

import Header from '../../components/Header';
import Tabs from '../../components/Tabs';
import DashboardMain from '../Dashboard/DashboardMain';

const tabs = [
  {
    Component: <DashboardMain />,
    tabName: 'Completed',
  },
  {
    Component: <DashboardMain />,
    tabName: 'Pending',
  },
  {
    Component: <DashboardMain />,
    tabName: 'Waiting For Payment',
  },
];

const Transactions: FC = () => (
  <Box sx={{ width: '100%' }}>
    <Header title="Transactions" />
    <Tabs tabs={tabs} />
  </Box>
);

export default Transactions;
