import { FC } from 'react';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend, LayoutPosition,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
  labels: ['BNB_BSC', 'ETH', 'USDT'],
  datasets: [
    {
      data: [12, 19, 3],
      backgroundColor: [
        '#0073E8',
        '#005BCF',
        '#9CD4FF',
      ],
      cutout: '85%',
    },
  ],
};

const bottom: LayoutPosition = 'bottom';

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: bottom,
      labels: {
        boxWidth: 16,
        boxHeight: 16,
        usePointStyle: true,
        padding: 20,
      },
    },
  },
};

const DoughnutChart: FC = () => <Doughnut data={data} options={options} />;

export default DoughnutChart;
