import { useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

import './App.css';
import { Box } from '@mui/material';
import Sidebar from './components/Sidebar';
import Dashboard from './features/Dashboard';

import logo from './icons/logo.png';
import Transactions from './features/Transactions';

const theme = createTheme({
  palette: {
    secondary: {
      main: blue[500],
    },
  },
  typography: {
    h1: {
      fontWeight: 700,
      fontSize: '1.875em',
      letterSpacing: 1,
    },
    h4: {
      fontWeight: 700,
      fontSize: '1em',
    },
    h6: {
      fontWeight: 700,
    },
    subtitle1: {
      fontWeight: 700,
      fontSize: '0.625em',
      letterSpacing: 1,
      color: 'rgba(136,136,136,1)',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '0.75rem',
    },
  },
});

function App() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <div className="wrapper">
          <BrowserRouter>
            <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            <Box
              sx={{
                zIndex: 9999,
                position: 'fixed',
                display: { xs: 'block', md: 'none' },
                top: '30px',
                right: '30px',
              }}
              onClick={handleDrawerToggle}
            >
              <img src={logo} className="orionLogo" alt="logo" />
            </Box>
            <main>
              <Routes>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/transactions" element={<Transactions />} />
                {/* <Route path="/sell_transactions" element={<SellTransactions />} /> */}
                <Route path="*" element={<h1>404 page</h1>} />
              </Routes>
            </main>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </div>
  );
}

export default App;
