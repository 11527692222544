import {
  FC, useState, SyntheticEvent, ReactElement,
} from 'react';
import { Box } from '@mui/material/';

import StyledTabs from './StyledTabs';
import StyledTab from './StyledTab';
import TabPanel from './TabPanel';

type Props = {
  tabs: {
    Component: ReactElement,
    tabName: string,
  }[],
};

const Tabs: FC<Props> = ({ tabs }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <StyledTabs
          value={value}
          onChange={handleChange}
        >
          {tabs.map(({ tabName }) => (
            <StyledTab key={tabName} label={tabName} />
          ))}
        </StyledTabs>
      </Box>
      {tabs.map(({ Component, tabName }, i) => (
        <TabPanel value={value} index={i} key={tabName}>
          {Component}
        </TabPanel>
      ))}
    </>
  );
};

export default Tabs;
