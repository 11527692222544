import { FC } from 'react';
import { Box, Typography, Grid } from '@mui/material/';
import { AttachMoneyOutlined, PersonAddAltOutlined } from '@mui/icons-material/';

import styles from './styles.module.scss';

type Props = {
  title: string,
  amount: string,
  change: string,
  icon: string,
}

const checkPlus = (amount: string) => amount[0] === '+';

const Card: FC<Props> = ({
  title, amount, change, icon,
}) => (
  <Grid item xs={12} sm={6} lg={3}>
    <Box
      sx={{
        border: 1,
        borderColor: 'divider',
        borderRadius: '0.5rem',
      }}
      className={styles.wrapper}
    >
      <div className={styles.leftBlock}>
        <Typography variant="subtitle1" component="h6">
          {title}
        </Typography>
        <div className={styles.data}>
          <Typography variant="h6" component="span" sx={{ marginRight: '0.5rem' }}>
            {amount}
          </Typography>
          <Typography
            variant="subtitle2"
            component="span"
            sx={
              {
                padding: '0.125rem',
                borderRadius: '0.25rem',
                backgroundColor: checkPlus(change) ? '#ccf7e5' : '#e63757',
                color: checkPlus(change) ? '#00d97e' : '#fff',
              }
            }
          >
            {change}
          </Typography>
        </div>
      </div>
      <span className={styles.dollar}>
        {icon === 'money' ? <AttachMoneyOutlined /> : <PersonAddAltOutlined sx={{ fontSize: 26 }} />}
      </span>
    </Box>
  </Grid>
);

export default Card;
