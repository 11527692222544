import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider,
} from '@mui/material/';
import Status from '../Status';

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fafafa',
    color: '#000',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
  cursor: 'pointer',
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  name: string,
  calories: string,
  fat: string,
  carbs: string,
) {
  return {
    name, calories, fat, carbs,
  };
}

const rows = [
  createData('€1,000.00', 'Completed', 'Bought 0.2093 ETH', '10/02/2022, 13:00:10'),
  createData('€1,000.00', 'Completed', 'Bought 0.2093 ETH', '10/02/2022, 13:00:10'),
  createData('€1,000.00', 'Completed', 'Bought 0.2093 ETH', '10/02/2022, 13:00:10'),
  createData('€1,000.00', 'Completed', 'Bought 0.2093 ETH', '10/02/2022, 13:00:10'),
  createData('€1,000.00', 'Completed', 'Bought 0.2093 ETH', '10/02/2022, 13:00:10'),
];

const cells = ['Amount', 'Status', 'Description', 'Created'];

const TableMain: FC = () => (
  <TableContainer sx={{
    border: 1,
    borderColor: 'divider',
    borderRadius: '0.5rem',
  }}
  >
    <Typography variant="h4" component="h4" sx={{ padding: '1rem 1.5rem' }}>
      Last transactions
    </Typography>
    <Divider />
    <Table sx={{ minWidth: 700 }} aria-label="customized table">
      <TableHead>
        <TableRow>
          {cells.map((title) => <StyledTableCell align="left">{title}</StyledTableCell>)}
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <StyledTableRow key={row.name} hover>
            <StyledTableCell align="left">{row.name}</StyledTableCell>
            <StyledTableCell align="left"><Status status="Completed" /></StyledTableCell>
            <StyledTableCell align="left">{row.fat}</StyledTableCell>
            <StyledTableCell align="left">{row.carbs}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TableMain;
