import { FC } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
};

const labels = ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb'];
const values = [1300, 200, 300, 1900, 500, 300, 700, 600, 500, 200, 1500, 2200, 1100];

const data = {
  labels,
  datasets: [
    {
      data: values,
      backgroundColor: '#067DF7',
      barThickness: 12,
      borderRadius: 6,
    },
  ],
};

const BarChart: FC = () => <Bar options={options} data={data} />;

export default BarChart;
