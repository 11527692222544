import { FC } from 'react';
import { Box } from '@mui/material/';

import Header from '../../components/Header';
import Tabs from '../../components/Tabs';
import DashboardMain from './DashboardMain';

const tabs = [
  {
    Component: <DashboardMain />,
    tabName: 'Buy',
  },
  {
    Component: <DashboardMain />,
    tabName: 'Sell',
  },
];

const Dashboard: FC = () => (
  <Box sx={{ width: '100%' }}>
    <Header title="Dashboard" />
    <Tabs tabs={tabs} />
  </Box>
);

export default Dashboard;
