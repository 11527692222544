import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = ({
  children, value, index, ...other
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    {...other}
  >
    {value === index && (
      <Box sx={{ paddingTop: 3 }}>{children}</Box>
    )}
  </div>
);

export default TabPanel;
