import { FC } from 'react';

import styles from './styles.module.scss';

// export enum StatusProps {
//   SUCCESS = 'Completed',
//   PENDING = 'pending',
//   FAILED = 'failed',
// }

type Props = {
  status: string,
}

const config = {
  completed: '#00d97e',
  pending: '#f6c343',
  failed: 'red',
};

const Status: FC<Props> = ({ status }) => (
  <div className={styles.wrapper}>
    <div className={styles.indicator} style={{ background: config.completed }} />
    <span>{status}</span>
  </div>
);

export default Status;
