import { Tab, styled } from '@mui/material/';

interface StyledTabProps {
  label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  padding: 0,
  minWidth: '60px',
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(16),
  marginRight: theme.spacing(2.5),
  color: 'rgba(102,102,102,1)',
  '&.Mui-selected': {
    color: 'rgba(0,0,0,1)',
  },
  '&:hover': {
    color: 'rgba(0,0,0,1)',
  },
}));

export default StyledTab;
