import { Typography } from '@mui/material';
import { FC } from 'react';

import styles from './styles.module.scss';

type Props = {
  title: string;
}

const Header: FC<Props> = ({ title }) => (
  <div className={styles.wrapper}>
    <Typography variant="subtitle1" component="h6" gutterBottom>
      OVERVIEW
    </Typography>
    <Typography variant="h1" component="h1">
      {title}
    </Typography>
  </div>
);

export default Header;
