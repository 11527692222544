import { FC } from 'react';
import { Grid } from '@mui/material';

import Cards from './Cards';
import BarChart from '../../../components/BarChart';
import Wrapper from '../../../components/Wrapper';
import DoughnutChart from '../../../components/DoughnutChart';
import LineChart from '../../../components/LineChart';
import TableMain from '../../../components/TableMain';

const DashboardMain: FC = () => (
  <>
    <Cards />
    <Grid container spacing={2} sx={{ marginTop: 3 }}>
      <Grid item xs={12} md={8} lg={8}>
        <Wrapper title="Volume">
          <BarChart />
        </Wrapper>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        <Wrapper title="Currencies">
          <DoughnutChart />
        </Wrapper>
      </Grid>
    </Grid>
    <Grid container sx={{ marginTop: 3 }}>
      <Grid item xs={12}>
        <Wrapper title="Earnings">
          <LineChart />
        </Wrapper>
      </Grid>
    </Grid>
    <Grid container sx={{ marginTop: 3 }}>
      <TableMain />
    </Grid>
  </>
);

export default DashboardMain;
