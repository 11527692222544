import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { FC } from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const labels = ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb'];
const values = [1300, 200, 300, 1900, 500, 300, 700, 600, 500, 200, 1500, 2200, 1100];

export const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
    line: {
      tension: 0.1,
    },
  },
  scales: {
    x: {
      grid: {
        drawBorder: false,
        display: false,
      },
    },
    y: {
      grid: {
        drawBorder: false,
        display: false,
      },
    },
  },
};

export const data = {
  labels,
  datasets: [
    {
      data: values,
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: '#067DF7',
    },
  ],
};

const LineChart: FC = () => <Line options={options} data={data} />;

export default LineChart;
