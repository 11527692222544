import { FC } from 'react';
import { Box, Divider, Typography } from '@mui/material/';

type Props = {
  title: string,
}

const Wrapper: FC<Props> = ({ title, children }) => (
  <Box
    sx={{
      border: 1,
      borderColor: 'divider',
      borderRadius: '0.5rem',
    }}
  >
    <Typography variant="h4" component="h4" sx={{ padding: '1rem 1.5rem' }}>
      {title}
    </Typography>
    <Divider />
    <Box sx={{ padding: '1.5rem', height: '22.5rem' }}>
      {children}
    </Box>
  </Box>
);

export default Wrapper;
