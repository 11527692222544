import { FC, useCallback } from 'react';
import {
  Box, Drawer, Divider, List, ListItemIcon, ListItemText, ListItemButton,
} from '@mui/material/';
import { HomeOutlined, CreditCardOutlined } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';

import styles from './styles.module.scss';

import logo from '../../icons/logo.png';

const drawerWidth = 240;

const DASHBOARD = 'dashboard';
const TRANSACTIONS = 'transactions';

type Props = {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

const Sidebar: FC<Props> = ({ mobileOpen, handleDrawerToggle }) => {
  const { pathname } = useLocation();

  const container = window !== undefined ? () => window.document.body : undefined;
  const isActive = useCallback((location: string) => `/${location}` === pathname, [pathname]);

  const drawer = (
    <div>
      <div className={styles.logoWrapper}>
        <img src={logo} className={styles.logo} alt="logo" />
      </div>
      <List>
        <ListItemButton
          component={Link}
          to={DASHBOARD}
          selected={isActive(DASHBOARD)}
          className="ListItemButton"
        >
          <ListItemIcon
            className="ListItemIcon"
          >
            <HomeOutlined />
          </ListItemIcon>
          <ListItemText
            className="ListItemText"
            primary="Dashboard"
          />
        </ListItemButton>
        <ListItemButton
          component={Link}
          to={TRANSACTIONS}
          selected={isActive(TRANSACTIONS)}
          className="ListItemButton"
        >
          <ListItemIcon
            className="ListItemIcon"
          >
            <CreditCardOutlined />
          </ListItemIcon>
          <ListItemText
            className="ListItemText"
            primary="Transactions"
          />
        </ListItemButton>
        {/* <ListItemButton
          component={Link}
          to={SELL_TRANSACTIONS}
          selected={isActive(SELL_TRANSACTIONS)}
          className="ListItemButton"
        >
          <ListItemIcon
            className="ListItemIcon"
          >
            <CreditCardOutlined />
          </ListItemIcon>
          <ListItemText
            className="ListItemText"
            primary="Sell transactions"
          />
        </ListItemButton> */}
      </List>
      <Divider />
    </div>
  );

  return (
    <Box
      component="nav"
      sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default Sidebar;
