import { FC } from 'react';
import { Grid } from '@mui/material/';

import Card from '../../../../components/Card';

const mockData = [
  {
    title: 'DAILY VOLUME',
    amount: '€3,498.04',
    change: '+103.51%',
    icon: 'money',
  },
  {
    title: 'MONTHLY VOLUME',
    amount: '€15,150.13',
    change: '+2.44%',
    icon: 'money',
  },
  {
    title: 'ACTIVE CUSTOMERS TODAY',
    amount: '3',
    change: '+50%',
    icon: 'user',
  },
  {
    title: 'ACTIVE CUSTOMERS THIS MONTH',
    amount: '29',
    change: '-9.37%',
    icon: 'user',
  },
];

// const Cards: FC = () => <Card {...mockData[0]} />;
const Cards: FC = () => (
  <Grid container spacing={2}>
    {mockData.map((props) => <Card {...props} />)}
  </Grid>
);

export default Cards;
