import { SyntheticEvent, ReactNode } from 'react';
import { Tabs, styled } from '@mui/material/';

interface StyledTabsProps {
  children?: ReactNode;
  value: number;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: '100%',
    width: '100%',
    backgroundColor: '#635ee7',
  },
});

export default StyledTabs;
